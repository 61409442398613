@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.Products {
    background-color:  #f3eded;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    margin-top: 0px;
  }
  .product-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .Products h2{
      width: 60%;
      margin:auto;
      margin-top: 50px;
      margin-bottom: 100px;
      color: rgb(9, 9, 9);
      font-size: 30px;
      font-family: "Lato";
  }
  .Products h2 span{
    font-family: "Lato";
    font-size: 40px;
    color: lightseagreen;
  }
  .productHeading{
    font-family: "Lato";
    width: 60%;
    margin-top:0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    color: rgb(9, 9, 9);
    font-size: 30px;
  }
  .productHeading span{
    color: lightseagreen;
  }
  
  .product-card {
    background: #4a4a4a;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(9, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin: 20px;
    width: 320px;
    height: 410px;
  }
  
  .product-card:hover {
    transform: translateY(-10px);
  }
  
  .product-image {
    width: 100%;
    height: 200px;
    object-fit: contain;
    margin: 2px;
  }
  .product-card p {
    font-size:15px ;
    padding: 0 15px 15px;
    color: #ffffff;
    font-family: "Lato";
  }
  .product-card h4{
    font-family: "Lato";
    color: lightseagreen;
    font-size: 20px;
  }
  