.client-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 40px;
  }
  