@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.OurServices {
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    background-color:  #eee9e9;
  }
  .ServicesIntro{
    color: rgb(10, 9, 9);
    font-size: 40px;
    width: 60%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
  }
  .secondIntro{
    width: 60%;
    margin: auto;
    font-size: 40px;
    margin-bottom: 40px;
    color: lightseagreen;
  }
  h2{
    color: lightseagreen;
    font-size: 40px;
    font-family: "Lato";
  }
  
  .service-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-items: center;
    
  }
  
  .service-card {
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease;
    width: 320px;
    height: 310px;
    margin: 20px;
    background: #4a4a4a;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
  }
  
  p {
    padding: 0 15px 15px;
    color: white;
    margin-top: 30px;
    font-size: 30px;
    font-family: "Lato";
    margin-bottom: 10px;
  }
  